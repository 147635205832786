.slide-container{
    padding: 5%;
    margin-top: 5%;
    @include flexCenter;
    flex-direction: column;
    h6{
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        opacity: 1;
    }
    img{
        margin-bottom: 10%;
        cursor: pointer;
        object-fit: contain;
        max-width: 216px;
        max-height: 216px;;
    }
}