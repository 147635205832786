.loading-false {
    height: 100vh;
    display: flex;
}
.login-page{
    height: 100vh;
    display: flex;
    // flex-direction: row;
    // justify-content: center;
    .left{
        @include flexCenter;
        flex-direction: column;
        text-align: center;
        color: $text-color;
        width: 40%;
        background-color: white;
        height: 100vh;
        filter: blur(6px);
    }
    .right{
        @include flexCenter;
        flex-direction: column;
        text-align: center;
        color: $text-color;
        width: 60%;
        height: 100vh;
        filter: blur(6px) contrast(0.8);
    }
}
.slider-popup-class{
    position: absolute !important;
    top: 10%;
    left: 10%;
    .modal-content{
        background-color: #f7f7f7e0 !important;
        width: 50%;
        position: relative;
        padding: 15px;
        .close-btn{
            border: none;
            width: 20px;
            align-self: flex-end;
            background-color: transparent;
            position: absolute;
            top: 5px;
            right: 5px;
            &:focus{
                outline: none;
            }
        }
    }
    .popup-wrapper{
        align-self: center;
        display: flex;
        flex-direction: column;
        padding: 20px;
        h5{
            margin: 0;
        }
        .vertical-slider-arrow{
            border: none;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
        .icon-wrapper{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 10px;
            cursor: pointer;
            user-select: none;
            .icon{
                margin-right: 10px;
            }
            h6{
                align-self: center;
                margin-bottom: 0;
            }
        }
    }
}






