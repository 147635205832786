.file-slide-container{
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 2%;
    @include flexCenter;
    flex-direction: column;
    h6{
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        opacity: 1;
    }
    img{
        cursor: pointer;
    }
}