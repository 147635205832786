.fullSize {
    width: 100%;
    height: 100%;
}

.smooth-image-wrapper {
    @extend .fullSize;
    position: relative;

    .smooth-image {
        transition: all 3s;
        //@extend .fullSize;
    }

    .smooth-no-image {
        display: flex;
        align-items: center;
        justify-content: center;
        @extend .fullSize;
        background-color: #fcfcfc;
        text-transform: capitalize;
    }

    .img-visible {
        opacity: 1;
        visibility: visible;
    }

    .img-hidden {
        opacity: 0;
        visibility: hidden;
    }

    @keyframes preloader-block {
        from {
            background-position: 0%, 0;
        }

        to {
            background-position: 170%, 0;
        }
    }

    .smooth-preloader {
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 10%;
        @extend .fullSize;
        background: rgba(245, 245, 245, 0.34);
        .loader {
            background-image: linear-gradient(to right, #e6e6e6, #ffffff, #e6e6e6);
            @extend .fullSize;
            display: inline-block;
            background-size: 200%;
            animation: preloader-block 2s linear 0s infinite reverse forwards;
        }
    }
}