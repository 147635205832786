.details-page{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .head{
        @include flexCenter;
        flex-direction: column;
        text-align: center;
        color: $text-color;
        margin-top: 2%;
        .heading{
            h3{
                font-size: 30px;
                font-weight: 400;
            }
            h1{
                font-size: 70px;
                font-weight: 300;
            }
        }
        .subheading{
            margin-top: 3%;
            h6{
                font-size: 20px;
                font-weight: 300;
            }
        }
    }
    .slider{
        margin: 0 5%;
    }
}


