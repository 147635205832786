.loader-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000000000000;
    background-color: #ffffffe7;
    .loader {
        @include absCenter;
    }
}
.loader-circle {
    width: 100px;
    height: 100px;
    position: relative;
    .sk-child{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        &::before{
            content: '';
            display: block;
            margin: 0 auto;
            width: 15%;
            height: 15%;
            background-color: #333;
            border-radius: 100%;
            -webkit-animation: loader-circleBounceDelay 1.2s infinite ease-in-out both;
                    animation: loader-circleBounceDelay 1.2s infinite ease-in-out both;
        }
    }
    .loader-circle2 {
        -webkit-transform: rotate(30deg);
            -ms-transform: rotate(30deg);
                transform: rotate(30deg);
        &::before{
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        } 
    }
    .loader-circle3 {
        -webkit-transform: rotate(60deg);
            -ms-transform: rotate(60deg);
                transform: rotate(60deg); 
        &::before{
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }
    }
    .loader-circle4 {
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
                transform: rotate(90deg);
        &::before{
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        } 
    }
    .loader-circle5 {
        -webkit-transform: rotate(120deg);
            -ms-transform: rotate(120deg);
                transform: rotate(120deg);
        &::before{
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
    }
    .loader-circle6 {
        -webkit-transform: rotate(150deg);
            -ms-transform: rotate(150deg);
                transform: rotate(150deg); 
        &::before{
            -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s;
        }
    }
    .loader-circle7 {
        -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
                transform: rotate(180deg); 
        &::before{
            -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
        }
    }
    .loader-circle8 {
        -webkit-transform: rotate(210deg);
            -ms-transform: rotate(210deg);
                transform: rotate(210deg); 
        &::before{
            -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
        }
    }
    .loader-circle9 {
        -webkit-transform: rotate(240deg);
            -ms-transform: rotate(240deg);
                transform: rotate(240deg); 
        &::before{
            -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
        }
    }
    .loader-circle10 {
        -webkit-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
                transform: rotate(270deg);
        &::before{
            -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
        } 
    }
    .loader-circle11 {
        -webkit-transform: rotate(300deg);
            -ms-transform: rotate(300deg);
                transform: rotate(300deg); 
        &::before{
            -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
        }
    }
    .loader-circle12 {
        -webkit-transform: rotate(330deg);
            -ms-transform: rotate(330deg);
                transform: rotate(330deg); 
        &::before{
            -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s;
        }
    }
  }
  @-webkit-keyframes loader-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes loader-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }




.download-loader {
    z-index: 100000000000;
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .download-loader-container{
        @include absCenter;
        .head{
            @include flexCenter;
            flex-direction: column;
            h5 {
                font-weight: 300;
                font-size: x-large;
                align-self: center;
            }
        }
        img {
            width: 100%;
            height: auto;
            border-radius: 70px;
        }
    }
}