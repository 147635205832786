.gallery-slide-container{
    // padding-top: 10%;
    padding-left: 20%;
    padding-right: 20%;
    margin-top: 5%;
    margin-right: 15%;
    @include flexCenter;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h6{
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        opacity: 1;
    }
    img{
        margin-bottom: 5%;
        cursor: pointer;
        object-fit: contain;
        max-width: 150px;
        max-height: 150px;
    }
}