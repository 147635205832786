

img{
    &.icon{
        width: 30px;
        height: 30px;
    }
}
.floor-switcher{
    position: relative;
}
.customize-popup-class{
    min-width: 1080px;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    z-index: 100;
    .modal-content{
        //border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        backdrop-filter: blur(12.5px);
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 30px;
        .close-btn{
            position: absolute;
            top: 20px;
            right: 20px;
            width: 30px;
            background: transparent;
            border: none;
            &:focus{
                outline: none;
            }
        }
    }
}
.customize-scene-page{
    // padding: 0 100px;
    @include flexCenter;
    justify-content: space-evenly;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.774);
    max-height: 90vh;
    border-radius: 30px;
    z-index: 100;
    .left-section{
        display: flex;
        .canvas-container{
            display: block;
            width: 480px;
            height: 480px;
            margin: 0 35px;
            .img-canvas{
                position: relative;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 20px;
                    width: 480px;
                    height: 480px;
                }
            }
        }
        .vertical-switcher {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            height: 500px;
            h5{
                font-size: 18px;
                font-weight: 400;
            }
            .icon-wrapper{
                @include flexCenter;
                flex-direction: column;
                cursor: pointer;
                margin-top: 25px;
                h6{
                   font-size: 14px; 
                   font-weight: 300;
                   margin-top: 10px;
                }
            }
        }
    }
    .right-section{
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        h4{
            font-size: 14px;
            font-weight: 300;
            margin: 5px 0 25px 0;
        }
        .heading{
            @include flexCenter;
            justify-content: space-evenly;
            width: 300px;
            margin-bottom: 30px;
            h2{
               font-size: 30px; 
               font-weight: 400;
               margin-bottom: 0;
            }
        }
        .product-switcher{
            @include flexCenter;
            flex-direction: column;
            width: 300px;
            height: 250px;
            margin-top: -60px;
            h5{
                width: 300px;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 15px;
            }
            .slick-slider{
                width: 240px;
                .slick-prev, .slick-next{
                    top: 22px;
                }
                .slick-prev{
                    left: -10px;
                }
                .slick-next{
                    right: -15px;
                }
                .slick-arrow{
                    &::before{
                        display: none;
                    }
                }
            }
            .icon-wrapper{
                display: flex !important;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                img{
                    border-radius: 0;
                    cursor: pointer;
                }
                h6{
                    font-size: 14px; 
                    font-weight: 300;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        .decoration-switcher{
            @include flexCenter; 
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 300px;
            height: 280px;
            padding-left: 10px;
            .icon-wrapper{
                @include flexCenter;
                flex-direction: column;
                width: 92px;
                h6{
                    font-size: 14px; 
                    font-weight: 300;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                .icon {
                    cursor: pointer;
                }
            }
            h5{
                width: 300px;
                text-align: center;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 10px;
            }
        }
        .img-downloader{
            @include flexCenter;
            button{
                position: absolute;
                bottom: 70px;
                padding: 15px 30px;
                font-size: 14px;
                font-weight: 400;
                width: 175px;
                border-radius: 15px;
                background-color: #fff;
                outline: none;
                border: 1.5px solid #707070;
                cursor: pointer;
                // box-shadow: 5px 5px 5px burlywood;
            }
        }
        .img-shuffle{
            @include flexCenter;
            button{
                position: absolute;
                bottom: 140px;
                padding: 15px 30px;
                font-size: 14px;
                width: 175px;
                font-weight: 400;
                border-radius: 15px;
                background-color: #fff;
                outline: none;
                border: 1.5px solid #707070;
                cursor: pointer;
                // box-shadow: 5px 5px 5px burlywood;
            }
        }
    }
}

.slider-popup-class{
    position: absolute !important;
    top: -25px;
    left: 0;
    .modal-content{
        background-color: rgba(255, 255, 255, 0.9) !important;
        backdrop-filter: blur(8px);
        border-radius: 10px;
        width: fit-content;
        height: 300px;
        position: relative;
        .close-btn{
            border: none;
            width: 20px;
            align-self: flex-end;
            background-color: transparent;
            position: absolute;
            top: 5px;
            right: 5px;
            &:focus{
                outline: none;
            }
        }
    }
    .popup-wrapper{
        align-self: center;
        display: flex;
        flex-direction: column;
        padding: 2px 2px;
        h5{
            margin: 0;
        }
        .vertical-slider-arrow{
            border: none;
            background-color: transparent;
            &:focus{
                outline: none;
            }
        }
        .slider-popup-class-icon-inside{
            align-self: center;
        }
        .icon-wrapper{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            user-select: none;
            .icon{
                margin-right: 5px;
            }
            h6{
                align-self: center;
                margin-bottom: 0;
                margin-left: 10px;
            }
        }
    }
}
.modal-backdrop{
    background-color: transparent !important;
}
.modal{
    position: fixed !important;
}
