.slick-slide {
    img {
        width: 100%;
        overflow: hidden;
        border-radius: 40px;
    }
    outline: none !important;
}
.slick-list {
    outline: none !important;
}

div:focus{
    outline: none !important;
}